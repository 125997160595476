import { render, staticRenderFns } from "./MarchEvent.vue?vue&type=template&id=5e789c94&scoped=true"
import script from "./MarchEvent.vue?vue&type=script&lang=js"
export * from "./MarchEvent.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/event/calendarfont.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "@/assets/css/event/marchevent.css?vue&type=style&index=1&id=5e789c94&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e789c94",
  null
  
)

export default component.exports