<template>
    <section class="event_container">
        <div class="event_title_area">
            <img class="march_flower1" src="/media/img/eventcalendar/marchflower1.png" alt="marchflower1" />
            <img class="march_flower2" src="/media/img/eventcalendar/marchflower2.png" alt="marchflower2" />
            <div class="event_01_title_wrap">
                <div class="event_01_title">
                    <figure class="event_01_title_bg">
                        <img class="event_01_title_bg_img" src="/media/img/eventcalendar/titie_wrap_march.png" alt="titie_wrap" />
                    </figure>
                    <h1 class="event_01_title_text">봄이 왔어요~</h1>
                </div>
            </div>
            <h1 class="calendar_title">3월 출석체크 이벤트</h1>
            <p class="calendar_event_desc pc">
                3월 한 달 동안 출석 체크 하시면 <br />
                푸짐한 선물을 팡팡 드려요!
            </p>
            <p class="calendar_event_desc mo">
                3월 한 달 동안 출석 체크 하시면 <br />
                푸짐한 선물을 팡팡 드려요!
            </p>
        </div>
        <calendar-comp v-if="isCalendarShow" :calendarwrapcss="'calendar_wrap_feb'" :monthcss="'march'" :monthtext="'March 2024'" :giftimg2url="'/media/img/eventcalendar/march_gift2.png'" :giftimg3url="`/media/img/eventcalendar/march_gift3.png`" />
        <div class="event_desc_group">
            <div class="event_info_1 event_desc1">
                <p class="event_desc1_title">기간</p>
                <p class="event_desc1_desc">3/1 ~ 3/31</p>
            </div>
            <div class="event_info_1 event_desc2">
                <p class="event_desc1_title">참여 대상</p>
                <p class="event_desc1_desc">매장용 또는 차량용 스페이스 구독자</p>
            </div>

            <div class="event_info_1 event_desc3">
                <p class="event_desc1_title">참여 방법</p>
                <p class="event_desc1_desc">플레이어로 노래를 재생하면 자동 출석 체크</p>
            </div>
        </div>

        <div class="event_gift_wrap">
            <div class="event_gift_title">
                <div class="event_gift_title_wrap">
                    <span class="gift_title_wrap"> Gift </span>
                </div>
                <h1 class="gitt_title_big title_mt">
                    <strong class="gitt_title_big_strong">출석체크</strong>
                    할 때마다 <br class="br_line" />더 커지는 <strong class="gitt_title_big_strong">선물</strong>!
                </h1>
            </div>
            <div class="event_gift">
                <div class="event_line">
                    <div class="event_circle circle1">
                        <svg style="width: 100%; height: 100%" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                            <circle cx="22" cy="22" r="21" fill="#FF4387" stroke="white" stroke-width="2" />
                        </svg>
                        <figure class="event_musical">
                            <img class="event_musical_icon" src="/media/img/eventcalendar/musical.png" alt="musical" />
                        </figure>
                    </div>
                    <span class="event_circle_text"> 25일 </span>

                    <div class="event_circle circle2">
                        <svg style="width: 100%; height: 100%" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                            <circle cx="22" cy="22" r="21" fill="#FF4387" stroke="white" stroke-width="2" />
                        </svg>
                        <figure class="event_musical">
                            <img class="event_musical_icon" src="/media/img/eventcalendar/musical.png" alt="musical" />
                        </figure>
                    </div>
                    <span class="event_circle_text event_circle2_text"> 15일 </span>

                    <div class="event_circle circle3">
                        <svg style="width: 100%; height: 100%" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                            <circle cx="22" cy="22" r="21" fill="#FF4387" stroke="white" stroke-width="2" />
                        </svg>
                        <figure class="event_musical">
                            <img class="event_musical_icon" src="/media/img/eventcalendar/musical.png" alt="musical" />
                        </figure>
                    </div>
                    <span class="event_circle3_text event_circle_text"> 3일 </span>
                </div>
                <ul class="event_step">
                    <li class="event_step_item">
                        <div class="event_step_last_item_text line2">
                            <span class="text_normal">25일 이상 재생시</span>
                            <strong class="text_em"
                            >다이슨 쿨 포름알데히드 <br />
                                공기청정기</strong
                            >
                        </div>

                        <div class="evnet_prize aircleaner">
                            <figure class="event_prize1_wrap aircleaner">
                                <img class="event_prize1_img" src="/media/img/eventcalendar/march_gift3.png" alt="march_gift3" />
                            </figure>
                            <div class="evnet_prize_count_circle evnet_prize1_count_circle">
                                <span class="evnet_prize_count_text"> 1명 </span>
                            </div>
                        </div>
                    </li>
                    <li class="event_step_item">
                        <div class="event_step_last_item_text">
                            <span class="text_normal">15일 이상 재생시</span>
                            <strong class="text_em">조말론 카 디퓨저</strong>
                        </div>

                        <div class="evnet_prize evnet_prize2 beef_wrap">
                            <figure class="event_prize2_wrap beef_big">
                                <img class="event_prize2_img" src="/media/img/eventcalendar/march_gift2.png" alt="march_gift2" />
                            </figure>
                            <div class="evnet_prize_count_circle evnet_prize2_count_circle">
                                <span class="evnet_prize_count_text"> 3명 </span>
                            </div>
                        </div>
                    </li>
                    <li class="event_step_item">
                        <div class="event_step_last_item_text">
                            <span class="text_normal">3일 이상 재생시</span>
                            <strong class="text_em">모바일 주유권 1만원</strong>
                        </div>

                        <div class="evnet_prize evnet_prize3 oil_bank">
                            <figure class="event_prize3_wrap">
                                <img class="event_prize3_img" src="/media/img/eventcalendar/mobile_oiling.png" alt="mobile_oiling" />
                            </figure>
                            <div class="evnet_prize_count_circle evnet_prize3_count_circle">
                                <span class="evnet_prize_count_text"> 100명 </span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="evnet_bonus">
            <div class="bonus_gift_title">
                <span class="gift_title_wrap"> Bonus Gift </span>
            </div>
            <h1 class="bonus_title2">매주 <strong class="bonus_title2_strong">일요일</strong>은 스타벅스 Day!</h1>

            <p class="bonus_desc">
                출석 일수와 상관없이 일요일에 노래를 들으면, <br />
                추첨을 통해 매주 5분께 스타벅스 아메리카노를 <br class="br_line2" />
                드립니다.
            </p>
            <div class="bonus_circle_wrap">
                <div class="bonus_circle">
                    <figure class="big_americano">
                        <img class="big_americano_img" src="/media/img/eventcalendar/americano_big.png" alt="americano_big" />
                    </figure>
                    <div class="evnet_prize_count_circle bonus_event_prize">
                        <span class="evnet_prize_count_text"> 5명 </span>
                    </div>
                </div>
            </div>
            <ul class="evnet_notice">
                <li class="event_notice_title">※ 유의사항</li>
                <li class="event_notice_contents">
                    <div class="event_contents_dot_wrap">
                        <span class="event_contents_dot"></span>
                    </div>
                    <p class="event_contents_desc">해당 이벤트는 조기 종료될 수 있습니다.</p>
                </li>
                <li class="event_notice_contents">
                    <div class="event_contents_dot_wrap">
                        <span class="event_contents_dot"></span>
                    </div>

                    <p class="event_contents_desc">제세공과금(경품 금액의 22%)은 당첨자 본인 부담이며, 당첨자는 개별적으로 연락드릴 예정입니다.</p>
                </li>
                <li class="event_notice_contents">
                    <div class="event_contents_dot_wrap">
                        <span class="event_contents_dot"></span>
                    </div>

                    <p class="event_contents_desc">이벤트 관련 문의사항은 1:1 문의로 부탁드립니다.</p>
                </li>
                <li class="event_notice_contents">
                    <div class="event_contents_dot_wrap">
                        <span class="event_contents_dot"></span>
                    </div>

                    <p class="event_contents_desc">경품 중복 당첨은 불가합니다.</p>
                </li>
                <li class="event_notice_contents">
                    <div class="event_contents_dot_wrap">
                        <span class="event_contents_dot"></span>
                    </div>

                    <p class="event_contents_desc">조건에 해당하는 인원이 없을 경우 경품이 제공되지 않을 수 있습니다.</p>
                </li>
                <li class="event_notice_contents">
                    <div class="event_contents_dot_wrap">
                        <span class="event_contents_dot"></span>
                    </div>

                    <p class="event_contents_desc">전체 마케팅 수신 동의를 하셔야 경품 지급이 가능합니다.</p>
                </li>
            </ul>
        </div>
    </section>
</template>
<script>
export default {
  name: 'MarchEvent',
  components: {
    'calendar-comp': () => import(`@/components/calendar/NextCalendarCompent.vue`)
  },
  data () {
    return {};
  },

  mounted () {},

  methods: {},
  computed: {
    isCalendarShow () {
      const curDate = new Date().getTime();
      const season = new Date('2024-03-31 23:59:59').getTime();

      if (curDate > season) {
        return false;
      }
      return true;
    }
  }
};
</script>
<style src="@/assets/css/event/calendarfont.css"></style>
<style scoped src="@/assets/css/event/marchevent.css"></style>
